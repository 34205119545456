import React, { useState, useEffect } from "react";
import Provider from "./provider";
import Router from "./router";
import Header from "./components/header";
import Loading from "./components/loading";
import styled from 'styled-components';
import { useGlobal } from "./provider/global";
import { parseColor } from "./utils/common";

const BodyContainer = styled.div`
  width: 100%;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgColor};
  font-family: ${(props) => props.fontFamily ? `'${props.fontFamily}' ` : 'inherit' } !important;
  min-height: 100vh;
`;

function Body({ Header, Router, Loading }) {
  const { settingsData } = useGlobal();
  const [color, setColor] = useState('#000');
  const [bgColor, setBgColor] = useState('#fff');
  const [fontFamily, setFontFamily] = useState('');

  useEffect(() => {
    if (!settingsData) return;
    setColor(parseColor(settingsData.txtColor));
    setBgColor(parseColor(settingsData.bgColor));
    setFontFamily(settingsData.fontFamily ? settingsData.fontFamily.trim() : 'inherit');
  }, [settingsData]);

  return (
    <BodyContainer color={color} bgColor={bgColor} fontFamily={fontFamily} >
      <Header />
      <Router />
      <Loading />
    </BodyContainer>
  );
}

function App() {
  return (
    <Provider>
      <Body 
        Header={Header}
        Router={Router}
        Loading={Loading}
      />
    </Provider>
  );
}

export default App;
