import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { useGlobal } from '../provider/global';
import { parseColor } from '../utils/common';

const Container = styled.div`
    height: 30px;
    width: 30px;
    right: 50%;
    position: fixed;
    bottom: 54px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(50%);
    z-index: 10;
    cursor: pointer;
`;

const ArrowDown = styled.div`
    border-bottom: 3px solid ${props => props.color};
    border-right: 3px solid ${props => props.color};
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
`;

const ArrowUp = styled.div`
    border-bottom: 3px solid ${props => props.color};
    border-right: 3px solid ${props => props.color};
    width: 15px;
    height: 15px;
    transform: rotate(225deg);
`;

function Indicator() {
    const { settingsData } = useGlobal();
    const [scrollHeight, setScrollHeight] = useState(0);
    const [pageHeight, setPageHeight] = useState(0);
    const [viewHeight, setViewHeight] = useState(0);
    const [btnColor, setBtnColor] = useState('');

    const handleMove = useCallback(() => {
        const dirUp = (pageHeight - scrollHeight - viewHeight) < 10;
        if (dirUp) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        } else {
            const section = scrollHeight / pageHeight;
            let toPoint = 0;
            if (section < 0.333333) {
                toPoint = viewHeight;
            } else if (section > 0.333333 && section < 0.666666) {
                toPoint = viewHeight * 2;
            } else {
                toPoint = viewHeight * 3;
            }
            window.scrollTo({
                top: toPoint,
                behavior: "smooth"
            });
        }
    }, [
        pageHeight,
        scrollHeight,
        viewHeight,
    ]);

    const handleScroll = (event) => {
        setViewHeight(window.innerHeight);
        setScrollHeight(window.pageYOffset);
        setPageHeight(document.body.getBoundingClientRect().height);
    };

    useEffect(() => {
        window.addEventListener('scroll', debounce(handleScroll, 30));
        setViewHeight(window.innerHeight);
        setScrollHeight(window.pageYOffset);
        setPageHeight(document.body.getBoundingClientRect().height);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        if (!settingsData) return;
        setBtnColor(parseColor(settingsData.btnColor));
    }, [settingsData]);

    return (
        <Container onClick={handleMove}>
            {(pageHeight - scrollHeight - viewHeight) < 10
                ? (
                    <ArrowUp color={btnColor} />
                ) : (
                    <ArrowDown color={btnColor}/>
                )
            }
        </Container>
    );
}

export default Indicator;