import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobal } from '../provider/global';
import { PAGE } from '../router/definition';
import parser from 'html-react-parser';
import { parseColor } from '../utils/common';
import CommonButton from '../components/commonButton';

const IntroContainer = styled.div`
    min-height: 100vh;
    height: auto;
    width: 100%;
    position: relative;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    padding: 20vh 20% 20vh 20%;
    text-align: left;
    word-break: break-all;
`;

const BottomContainer = styled.div`
    width: 100%;
    height: 200px;
    padding-bottom: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`;


function Intro() {
    const { 
        settingsData,
        introData,
        setIsLoading,
    } = useGlobal();

    const [txtColor, setTxtColor] = useState('');
    const [bgColor, setBgColor] = useState('');
    const [introText, setIntroText] = useState('');

    useEffect(() => {
        if (!settingsData) return;
        setTxtColor(parseColor(settingsData.txtColor));
        setBgColor(parseColor(settingsData.bgColor));
    }, [settingsData]);

    useEffect(() => {
        if (!introData) return;
        setIntroText(introData.mainText);
        setIsLoading(false);
    }, [introData]);

    return (
        <IntroContainer bgColor={bgColor} color={txtColor}>
            {introText && parser(introText)}
            <BottomContainer>
                <CommonButton text={'home'} route={PAGE.HOME} isBack={true}></CommonButton>
            </BottomContainer>
        </IntroContainer>
    );
}

export default Intro;