import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageContainer } from '../style/layout';
import { Tabs, Tab } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useGlobal } from '../provider/global';
import { Button, Form } from 'react-bootstrap';
import ColorPicker from '../utils/colorPicker';
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from '../constants';
import { getBase64 } from '../utils/common';
import { PAGE } from '../router/definition';

const times = [
    500,
    1000,
    1500,
    2000,
    2500,
    3000,
    3500,
    4000,
    4500,
    5000,
    5500,
    6000,
    7000,
    8000,
    9000,
    10000,
    15000,
    30000
];

const types = [
    'slide',
    'fade',
];

function Settings() {
    const history = useHistory();
    const {
        isAuth,
        settingsData,
        homeData,
        introData,
        sendEmailData,
        changeFlag,
        setChangeFlag,
    } = useGlobal();


    const [key, setKey] = useState('setting');
    const [receiveEmail, setReceiveEmail] = useState('');
    const [cities, setCities] = useState('Seoul, Busan');
    const [bgColor, setBgColor] = useState({ r:0, g:0, b:0, a:1 });
    const [btnColor, setBtnColor] = useState({ r:255, g:255, b:255, a:1 });
    const [txtColor, setTxtColor] = useState({ r:255, g:255, b:255, a:1 });
    const [headerTitle, setHeaderTitle] = useState('');
    const [fontFamily, setFontFamily] = useState('');

    const [section1Text, setSection1Text] = useState('');
    const [section1SliderSpeed, setSection1SliderSpeed] = useState(500);
    const [section1SliderEffect, setSection1SliderEffect] = useState('slide');
    const [homeImages, setHomeImages] = useState(['', '', '', '', '']);
    const [homeImagesCheck, setHomeImagesCheck] = useState(new Array(5));
    const [homeImagesTypes, setHomeImagesTypes] = useState(new Array(5));
    const [homeImagesDeleteCheck, setHomeImagesDeleteCheck] = useState([false, false, false, false, false]);
    const [section1BtnText, setSection1BtnText] = useState('');
    const [section2Text, setSection2Text] = useState('');
    const [section2BtnText, setSection2BtnText] = useState('');
    const [section3Text, setSection3Text] = useState('');
    const [section3FooterText, setSection3FooterText] = useState('');

    const [introText, setIntroText] = useState('');

    const [sendEmailText, setSendEmailText] = useState('');
    const [locationTitle, setLocationTitle] = useState('LOCATION');
    const [replyEmail, setReplyEmail] = useState('REPLY EMAIL');
    const [emailMessages, setEmailMessages] = useState('MESSAGE1, MESSAGE2, MESSAGE3');
    const [emailImage1Title, setEmailImage1Title] = useState('IMAGE1');
    const [emailImage2Title, setEmailImage2Title] = useState('IMAGE2');
    const [emailImage3Title, setEmailImage3Title] = useState('IMAGE3');
    const [emailImage4Title, setEmailImage4Title] = useState('IMAGE4');
    const [captchaTitle, setCaptchaTitle] = useState('Add two numbers and write them down.');

    const [isDisabled, setIsDisabled] = useState(false);

    const handleChangeReceiveEmail = (event) => {
        setReceiveEmail(event.target.value);
    };

    const handleChangeCities = (event) => {
        setCities(event.target.value);
    };

    const onSelectBgColor = (color) => {
        setBgColor(color.rgb);
    };

    const onSelectBtnColor = (color) => {
        setBtnColor(color.rgb);
    };

    const onSelectTxtColor = (color) => {
        setTxtColor(color.rgb);
    };

    const handleFont = (event) => {
        setFontFamily(event.target.value);
    };

    const handleSaveSettingData = useCallback(async (event) => {
        event.preventDefault();
        const reg = /[\{\}\[\]\/?.;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\(\=]/gi;
        const citiesParsed = cities.replace(reg, "").trim();
        const citiesArr = citiesParsed.split(',');
        setIsDisabled(true);
        await setDoc(doc(db, "settings", "settings"), {
            receiveEmail,
            cities: citiesArr,
            bgColor: JSON.stringify(bgColor),
            btnColor: JSON.stringify(btnColor),
            txtColor: JSON.stringify(txtColor),
            headerTitle: headerTitle ?? ' ',
            fontFamily: fontFamily ?? ' ',
        });
        alert('저장되었습니다.');
        setIsDisabled(false);
        setChangeFlag(changeFlag + 1);
    }, [receiveEmail, cities, bgColor, btnColor, txtColor, headerTitle, fontFamily, changeFlag]);

    const handleSection1SliderSpeed = (event) => {
        setSection1SliderSpeed(event.target.value);
    };

    const handleSection1SliderEffect = (event) => {
        setSection1SliderEffect(event.target.value);
    }

    const setFile = useCallback(async (event, number) => {
        const file = event.target.files[0];
        const type = file.type;
        if (type.search('image') === -1) return;
        const base64 = await getBase64(file);
        const cloneHomeImages = [...homeImages];
        const cloneHomeImagesTypes = [...homeImagesTypes];
        cloneHomeImages.splice(number, 1, base64);
        cloneHomeImagesTypes.splice(number, 1, type);
        setHomeImages(cloneHomeImages);
        setHomeImagesTypes(cloneHomeImagesTypes);
    }, [homeImages, homeImagesTypes]);

    const handleChangeSection1BtnText = (event) => {
        setSection1BtnText(event.target.value);
    };

    const handleChangeSection2BtnText = (event) => {
        setSection2BtnText(event.target.value);
    };

    const handleChangeSection3FooterText = (event) => {
        setSection3FooterText(event.target.value);
    };

    const handleHomeImageDelete = useCallback((event, i) => {
        const clone = [...homeImagesDeleteCheck];
        clone.splice(i, 1, event.target.checked);
        setHomeImagesDeleteCheck(clone);
    }, [homeImagesDeleteCheck]);

    const handleSaveHomeData = useCallback(async (event) => {
        event.preventDefault();
        setIsDisabled(true);

        const imagesRef = storage.ref().child('images');
        const homeImagesName = new Array(5);
        
        let n = 0;
        if (homeImagesDeleteCheck[n]) {
            homeImagesName[n] = '';
        } else {
            if (homeImages[n] && homeImagesTypes[n]) {
                const extension = homeImagesTypes[n].split('/')[1];
                const img1Ref = imagesRef.child(`${n}.${extension}`);
                const snapshot = img1Ref.putString(homeImages[n], 'data_url');
                homeImagesName[n] = `${n}.${extension}`;
            } else {
                homeImagesName[n] = homeImagesCheck[n] ?? '';
            }
        }
        n++;
        if (homeImagesDeleteCheck[n]) {
            homeImagesName[n] = '';
        } else {
            if (homeImages[n] && homeImagesTypes[n]) {
                const extension = homeImagesTypes[n].split('/')[1];
                const img1Ref = imagesRef.child(`${n}.${extension}`);
                const snapshot = img1Ref.putString(homeImages[n], 'data_url');
                homeImagesName[n] = `${n}.${extension}`;
            } else {
                homeImagesName[n] = homeImagesCheck[n] ?? '';
            }
        }
        n++;
        if (homeImagesDeleteCheck[n]) {
            homeImagesName[n] = '';
        } else {
            if (homeImages[n] && homeImagesTypes[n]) {
                const extension = homeImagesTypes[n].split('/')[1];
                const img1Ref = imagesRef.child(`${n}.${extension}`);
                const snapshot = img1Ref.putString(homeImages[n], 'data_url');
                homeImagesName[n] = `${n}.${extension}`;
            } else {
                homeImagesName[n] = homeImagesCheck[n] ?? '';
            }
        }
        n++;
        if (homeImagesDeleteCheck[n]) {
            homeImagesName[n] = '';
        } else {
            if (homeImages[n] && homeImagesTypes[n]) {
                const extension = homeImagesTypes[n].split('/')[1];
                const img1Ref = imagesRef.child(`${n}.${extension}`);
                const snapshot = img1Ref.putString(homeImages[n], 'data_url');
                homeImagesName[n] = `${n}.${extension}`;
            } else {
                homeImagesName[n] = homeImagesCheck[n] ?? '';
            }
        }
        n++;
        if (homeImagesDeleteCheck[n]) {
            homeImagesName[n] = '';
        } else {
            if (homeImages[n] && homeImagesTypes[n]) {
                const extension = homeImagesTypes[n].split('/')[1];
                const img1Ref = imagesRef.child(`${n}.${extension}`);
                const snapshot = img1Ref.putString(homeImages[n], 'data_url');
                homeImagesName[n] = `${n}.${extension}`;
            } else {
                homeImagesName[n] = homeImagesCheck[n] ?? '';
            }
        }

        const params = {
            homeImages: homeImagesName,
            section1Text: section1Text ?? ' ',
            section1SliderSpeed: section1SliderSpeed ?? 500, 
            section1SliderEffect: section1SliderEffect ?? 'slide',
            section1BtnText: section1BtnText ?? ' ',
            section2Text: section2Text ?? ' ',
            section2BtnText: section2BtnText ?? ' ',
            section3Text: section3Text ?? ' ',
            section3FooterText: section3FooterText ?? ' ',
        };

        
        await setDoc(doc(db, "settings", "home"), params);
        alert('저장되었습니다.');
        setIsDisabled(false);
        setChangeFlag(changeFlag + 1);
    }, [section1Text,
        section1SliderSpeed, 
        section1SliderEffect,
        homeImages,
        homeImagesTypes,
        homeImagesCheck,
        section1BtnText,
        section2Text,
        section2BtnText,
        section3Text,
        section3FooterText,
        changeFlag,
        homeImagesDeleteCheck,
    ]);

    const handleSaveIntroData = useCallback(async () => {
        setIsDisabled(true);
        await setDoc(doc(db, "settings", "intro"), {
            mainText: introText ?? ' ',
        });
        alert('저장되었습니다.');
        setIsDisabled(false);
        setChangeFlag(changeFlag + 1);
    }, [introText, changeFlag]);

    const handleSaveEmailData = useCallback(async (event) => {
        event.preventDefault();
        setIsDisabled(true);
        await setDoc(doc(db, "settings", "sendEmail"), {
            mainText: sendEmailText ?? ' ',
            locationTitle: locationTitle ?? ' ',
            replyEmailTitle: replyEmail ?? ' ',
            messageTitles: emailMessages ?? ' ',
            image1Title: emailImage1Title ?? ' ',
            image2Title: emailImage2Title ?? ' ',
            image3Title: emailImage3Title ?? ' ',
            image4Title: emailImage4Title ?? ' ',
            captchaTitle: captchaTitle ?? ' ',
        });
        alert('저장되었습니다.');
        setIsDisabled(false);
        setChangeFlag(changeFlag + 1);
    }, [
        sendEmailText,
        locationTitle,
        replyEmail,
        emailMessages,
        emailImage1Title,
        emailImage2Title,
        emailImage3Title,
        emailImage4Title,
        captchaTitle,
        changeFlag,
    ]);

    const goHome = useCallback(() => {
        history.push(PAGE.HOME);
    }, [history]);

    useEffect(() => {
        if (!settingsData) return;
        const citiesStr = settingsData.cities.length ? settingsData.cities.join(',') : '';
        const bgColorObj = settingsData.bgColor ? JSON.parse(settingsData.bgColor) : { r:0, g:0, b:0, a:1 };
        const btnColorObj = settingsData.btnColor ? JSON.parse(settingsData.btnColor) : { r:255, g:255, b:255, a:1 };
        const txtColorObj = settingsData.txtColor ? JSON.parse(settingsData.txtColor) : { r:255, g:255, b:255, a:1 };
        setReceiveEmail(settingsData.receiveEmail ?? '');
        setCities(citiesStr);
        setBgColor(bgColorObj);
        setBtnColor(btnColorObj);
        setTxtColor(txtColorObj);
        setHeaderTitle(settingsData.headerTitle ?? '');
        setFontFamily(settingsData.fontFamily);
    }, [settingsData]);

    useEffect(() => {
        if (!homeData) return;
        setSection1Text(homeData.section1Text);
        setSection1SliderSpeed(homeData.section1SliderSpeed ? Number(homeData.section1SliderSpeed) : 500);
        setSection1SliderEffect(homeData.section1SliderEffect);
        setSection1BtnText(homeData.section1BtnText);
        setHomeImagesCheck(homeData.homeImages);
        setSection2Text(homeData.section2Text);
        setSection2BtnText(homeData.section2BtnText);
        setSection3Text (homeData.section3Text);
        setSection3FooterText(homeData.section3FooterText);
    }, [homeData]);

    useEffect(() => {
        if (!introData) return;
        setIntroText(introData.mainText);
    }, [introData]);

    useEffect(() => {
        if (!sendEmailData) return;
        setSendEmailText(sendEmailData.mainText);
        setLocationTitle(sendEmailData.locationTitle);
        setReplyEmail(sendEmailData.replyEmailTitle);
        setEmailMessages(sendEmailData.messageTitles);
        setEmailImage1Title(sendEmailData.image1Title);
        setEmailImage2Title(sendEmailData.image2Title);
        setEmailImage3Title(sendEmailData.image3Title);
        setEmailImage4Title(sendEmailData.image4Title);
        setCaptchaTitle(sendEmailData.captchaTitle);
    }, [sendEmailData]);

    return (
        <PageContainer>
            <div style={{ height: 0, width: '100%', position: 'relative' }}>
                <div style={{ position: 'absolute', top: -10, right: 30 }}>
                    <Button type='button' onClick={goHome}>HOME으로</Button>
                </div>
            </div>
            <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            style={{ width: '100%' }}
            >
                <Tab eventKey="setting" title="기본 정보" style={{ width: '100%', paddingTop: 20 }}>
                    <h3>기본 정보 수정</h3>
                    <Form onSubmit={handleSaveSettingData}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>이메일 주소</Form.Label>
                            <Form.Control type="email" placeholder="email@email.com" onChange={handleChangeReceiveEmail} value={receiveEmail} />
                            <Form.Text className="text-muted">
                                고객문의를 수신받을 이메일 주소입니다.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicSelectList">
                            <Form.Label>도시 목록</Form.Label>
                            <Form.Control type="text" placeholder="Seoul, Los Angeles, London" onChange={handleChangeCities} value={cities}/>
                            <Form.Text className="text-muted">
                                고객문의가 가능한 도시를 입력하세요. 도시명을 쉼표(,)로 나누어 입력하세요.(예: Seoul, Los Angeles, London)
                            </Form.Text>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formBackgroundColor">
                            <Form.Label>배경색</Form.Label>
                            <ColorPicker color={bgColor} onSelect={onSelectBgColor} />
                            <Form.Text className="text-muted">
                                홈페이지 전체 배경색을 설정합니다.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBtnColor">
                            <Form.Label>버튼색</Form.Label>
                            <ColorPicker color={btnColor} onSelect={onSelectBtnColor}  />
                            <Form.Text className="text-muted">
                                홈페이지 전체 버튼색을 설정합니다.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formTxtColor">
                            <Form.Label>글자색</Form.Label>
                            <ColorPicker color={txtColor} onSelect={onSelectTxtColor}  />
                            <Form.Text className="text-muted">
                                버튼을 제외한 홈페이지 전체 글자색을 설정합니다.
                            </Form.Text>
                        </Form.Group>

                        <Form.Label>홈페이지 타이틀</Form.Label>
                        <Form.Text className="text-muted">
                           : 홈페이지 상단에 고정된 위치에서 보이는 홈페이지 타이틀입니다. 
                        </Form.Text>
                        <CKEditor
                            editor={ClassicEditor}
                            data={headerTitle}
                            onReady={ editor => {
                                // You can store the "editor" and use when it is needed.
                            } }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setHeaderTitle(data);
                            } }
                            onBlur={ editor => {
                            } }
                            onFocus={ editor => {
                            } }
                        />
                        <Form.Group>
                            <Form.Label>폰트 설정 ( 브라우저에 따라 적용이 안될 수도 있습니다. )</Form.Label>
                            <Form.Select onChange={handleFont} value={fontFamily}>
                                <option>Adobe Arabic</option>
                                <option>Adobe Caslon Pro</option>
                                <option>Adobe Devanagari</option>
                                <option>Adobe Fan Heiti Std</option>
                                <option>Adobe Fangsong Std</option>
                                <option>Adobe Garamond Pro</option>
                                <option value="Adobe Gothic Std">Adobe 고딕 Std</option>
                                <option>Adobe Hebrew</option>
                                <option>Adobe Heiti Std</option>
                                <option>Adobe Kaiti Std</option>
                                <option>Adobe Ming Std</option>
                                <option value="Adobe Myungjo Std">Adobe 명조 Std</option>
                                <option>Adobe Naskh</option>
                                <option>Adobe Song Std</option>
                                <option>Al Bayan</option>
                                <option>Al Nile</option>
                                <option>Al Tarikh</option>
                                <option>American Typewriter</option>
                                <option>Andale Mono</option>
                                <option>Apple Braille</option>
                                <option>Apple Chancery</option>
                                <option>Apple Color Emoji</option>
                                <option>Apple LiGothic</option>
                                <option value="Apple SD Gothic Neo">Apple SD 산돌고딕 Neo</option>
                                <option>Apple Symbols</option>
                                <option>AppleGothic</option>
                                <option>AppleMyungjo</option>
                                <option>Arial</option>
                                <option>Arial Black</option>
                                <option>Arial Hebrew</option>
                                <option>Arial Hebrew Scholar</option>
                                <option>Arial Narrow</option>
                                <option>Arial Rounded MT Bold</option>
                                <option>Arial Unicode MS</option>
                                <option>Avenir</option>
                                <option>Avenir Next</option>
                                <option>Avenir Next Condensed</option>
                                <option>Ayuthaya</option>
                                <option>Baghdad</option>
                                <option>Bangla MN</option>
                                <option>Bangla Sangam MN</option>
                                <option>Baoli SC</option>
                                <option>Baoli TC</option>
                                <option>Baskerville</option>
                                <option>Beirut</option>
                                <option>BiauKai</option>
                                <option>Big Caslon</option>
                                <option>Birch Std</option>
                                <option>Blackoak Std</option>
                                <option value="BM DoHyeon OTF">배달의민족 도현 OTF</option>
                                <option>Bodoni 72</option>
                                <option>Bodoni 72 Oldstyle</option>
                                <option>Bodoni 72 Smallcaps</option>
                                <option>Bodoni Ornaments</option>
                                <option>Bradley Hand</option>
                                <option>Brush Script MT</option>
                                <option>Brush Script Std</option>
                                <option>Century Gothic</option>
                                <option>Chalkboard</option>
                                <option>Chalkboard SE</option>
                                <option>Chalkduster</option>
                                <option>Chaparral Pro</option>
                                <option>Charlemagne Std</option>
                                <option>Charter</option>
                                <option>Cochin</option>
                                <option>Comic Sans MS</option>
                                <option>Cooper Std</option>
                                <option>Copperplate</option>
                                <option>Corsiva Hebrew</option>
                                <option>Courier</option>
                                <option>Courier New</option>
                                <option>Damascus</option>
                                <option>DecoType Naskh</option>
                                <option>Devanagari MT</option>
                                <option>Devanagari Sangam MN</option>
                                <option>Didot</option>
                                <option>DIN Alternate</option>
                                <option>DIN Condensed</option>
                                <option>Diwan Kufi</option>
                                <option>Diwan Thuluth</option>
                                <option>Euphemia UCAS</option>
                                <option>Farah</option>
                                <option>Farisi</option>
                                <option>Futura</option>
                                <option>Galvji</option>
                                <option>GB18030 Bitmap</option>
                                <option>Geeza Pro</option>
                                <option>Geneva</option>
                                <option>Georgia</option>
                                <option>Giddyup Std</option>
                                <option>Gill Sans</option>
                                <option>Graphik</option>
                                <option>Gujarati MT</option>
                                <option>Gujarati Sangam MN</option>
                                <option value="GungSeo">궁서체</option>
                                <option>Gurmukhi MN</option>
                                <option>Gurmukhi MT</option>
                                <option>Gurmukhi Sangam MN</option>
                                <option>Hannotate SC</option>
                                <option>Hannotate TC</option>
                                <option>HanziPen SC</option>
                                <option>HanziPen TC</option>
                                <option value="HeadLineA">헤드라인A</option>
                                <option>Hei</option>
                                <option value="Heiti SC">Heiti-간체</option>
                                <option value="Heiti TC">Heiti-번체</option>
                                <option>Helvetica</option>
                                <option>Helvetica Neue</option>
                                <option>Herculanum</option>
                                <option>Hiragino Maru Gothic ProN</option>
                                <option>Hiragino Mincho ProN</option>
                                <option>Hiragino Sans</option>
                                <option>Hiragino Sans CNS</option>
                                <option>Hiragino Sans GB</option>
                                <option>Hobo Std</option>
                                <option>Hoefler Text</option>
                                <option>Impact</option>
                                <option>InaiMathi</option>
                                <option>ITF Devanagari</option>
                                <option>ITF Devanagari Marathi</option>
                                <option>Kai</option>
                                <option>Kailasa</option>
                                <option>Kaiti SC</option>
                                <option>Kaiti TC</option>
                                <option>Kannada MN</option>
                                <option>Kannada Sangam MN</option>
                                <option>Kefa</option>
                                <option>Khmer MN</option>
                                <option>Khmer Sangam MN</option>
                                <option>Klee</option>
                                <option>Kohinoor Bangla</option>
                                <option>Kohinoor Devanagari</option>
                                <option>Kohinoor Gujarati</option>
                                <option>Kohinoor Telugu</option>
                                <option>Kokonor</option>
                                <option>Kozuka Gothic Pr6N</option>
                                <option>Kozuka Gothic Pro</option>
                                <option>Kozuka Mincho Pr6N</option>
                                <option>Kozuka Mincho Pro</option>
                                <option>Krungthep</option>
                                <option>KufiStandardGK</option>
                                <option>Lantinghei SC</option>
                                <option>Lantinghei TC</option>
                                <option>Lao MN</option>
                                <option>Lao Sangam MN</option>
                                <option>Letter Gothic Std</option>
                                <option>Libian SC</option>
                                <option>Libian TC</option>
                                <option>LiHei Pro</option>
                                <option>LingWai SC</option>
                                <option>LingWai TC</option>
                                <option>LiSong Pro</option>
                                <option>Lithos Pro</option>
                                <option>Lucida Grande</option>
                                <option>Luminari</option>
                                <option>Malayalam MN</option>
                                <option>Malayalam Sangam MN</option>
                                <option>Marker Felt</option>
                                <option>Menlo</option>
                                <option>Mesquite Std</option>
                                <option>Microsoft Sans Serif</option>
                                <option>Minion Pro</option>
                                <option>Mishafi</option>
                                <option>Mishafi Gold</option>
                                <option>Monaco</option>
                                <option>Mshtakan</option>
                                <option>Mukta Mahee</option>
                                <option>Muna</option>
                                <option>Myanmar MN</option>
                                <option>Myanmar Sangam MN</option>
                                <option>Myriad Arabic</option>
                                <option>Myriad Hebrew</option>
                                <option>Myriad Pro</option>
                                <option>Nadeem</option>
                                <option value="Nanum Brush Script">나눔손글씨 붓</option>
                                <option value="Nanum Gothic">나눔고딕</option>
                                <option value="Nanum Myeongjo">나눔명조</option>
                                <option value="Nanum Pen Script">나눔손글씨 펜</option>
                                <option>New Peninim MT</option>
                                <option>Noteworthy</option>
                                <option>Noto Nastaliq Urdu</option>
                                <option>Noto Sans Javanese</option>
                                <option>Noto Sans Kannada</option>
                                <option>Noto Sans Myanmar</option>
                                <option>Noto Sans Oriya</option>
                                <option>Noto Serif Myanmar</option>
                                <option>Nueva Std</option>
                                <option>OCR A Std</option>
                                <option>Optima</option>
                                <option>Orator Std</option>
                                <option>Oriya MN</option>
                                <option>Oriya Sangam MN</option>
                                <option>Palatino</option>
                                <option>Papyrus</option>
                                <option value="PCMyungjo">PC명조</option>
                                <option>Phosphate</option>
                                <option value="PilGi">필기체</option>
                                <option>PingFang HK</option>
                                <option>PingFang SC</option>
                                <option>PingFang TC</option>
                                <option>Plantagenet Cherokee</option>
                                <option>Poplar Std</option>
                                <option>Prestige Elite Std</option>
                                <option>PSL Ornanong Pro</option>
                                <option>PT Mono</option>
                                <option>PT Sans</option>
                                <option>PT Sans Caption</option>
                                <option>PT Sans Narrow</option>
                                <option>PT Serif</option>
                                <option>PT Serif Caption</option>
                                <option>Raanana</option>
                                <option>Rockwell</option>
                                <option>Rosewood Std</option>
                                <option>Sana</option>
                                <option>Sathu</option>
                                <option>Savoye LET</option>
                                <option>Shree Devanagari 714</option>
                                <option>SignPainter</option>
                                <option>Silom</option>
                                <option>Sinhala MN</option>
                                <option>Sinhala Sangam MN</option>
                                <option>Skia</option>
                                <option>Snell Roundhand</option>
                                <option>Songti SC</option>
                                <option>Songti TC</option>
                                <option>Stencil Std</option>
                                <option>STFangsong</option>
                                <option>STHeiti</option>
                                <option>STIXGeneral</option>
                                <option>STIXIntegralsD</option>
                                <option>STIXIntegralsSm</option>
                                <option>STIXIntegralsUp</option>
                                <option>STIXIntegralsUpD</option>
                                <option>STIXIntegralsUpSm</option>
                                <option>STIXNonUnicode</option>
                                <option>STIXSizeFiveSym</option>
                                <option>STIXSizeFourSym</option>
                                <option>STIXSizeOneSym</option>
                                <option>STIXSizeThreeSym</option>
                                <option>STIXSizeTwoSym</option>
                                <option>STIXVariants</option>
                                <option>STKaiti</option>
                                <option>STSong</option>
                                <option>Sukhumvit Set</option>
                                <option>Symbol</option>
                                <option>Tahoma</option>
                                <option>Tamil MN</option>
                                <option>Tamil Sangam MN</option>
                                <option>Tekton Pro</option>
                                <option>Telugu MN</option>
                                <option>Telugu Sangam MN</option>
                                <option>Thonburi</option>
                                <option>Times</option>
                                <option>Times New Roman</option>
                                <option>Toppan Bunkyu Gothic</option>
                                <option>Toppan Bunkyu Midashi Gothic</option>
                                <option>Toppan Bunkyu Midashi Mincho</option>
                                <option>Toppan Bunkyu Mincho</option>
                                <option>Trajan Pro</option>
                                <option>Trattatello</option>
                                <option>Trebuchet MS</option>
                                <option>Tsukushi A Round Gothic</option>
                                <option>Tsukushi B Round Gothic</option>
                                <option>Verdana</option>
                                <option>Waseem</option>
                                <option>Wawati SC</option>
                                <option>Wawati TC</option>
                                <option>Webdings</option>
                                <option>Weibei SC</option>
                                <option>Weibei TC</option>
                                <option>Wingdings</option>
                                <option>Wingdings 2</option>
                                <option>Wingdings 3</option>
                                <option>Xingkai SC</option>
                                <option>Xingkai TC</option>
                                <option>Yuanti SC</option>
                                <option>Yuanti TC</option>
                                <option>YuGothic</option>
                                <option>YuMincho</option>
                                <option>YuMincho +36p Kana</option>
                                <option>Yuppy SC</option>
                                <option>Yuppy TC</option>
                                <option>Zapf Dingbats</option>
                                <option>Zapfino</option>
                            </Form.Select>
                        </Form.Group>
                        <div style={{ width: '100%', height: 20 }}></div>
                        <Button variant="danger" type='submit' size="lg" disabled={isDisabled}>저장</Button>
                    </Form>
                </Tab>
                <Tab eventKey="home" title="홈" style={{ width: '100%', paddingTop: 20 }}>
                    <h3>홈 내용 수정</h3>
                    <Form onSubmit={handleSaveHomeData}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>1번 섹션 내용</Form.Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={section1Text}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setSection1Text(data);
                                } }
                                onBlur={ editor => {
                                } }
                                onFocus={ editor => {
                                } }
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicSelectList">
                            <Form.Label>1번 섹션 슬라이더 속도</Form.Label>
                            <Form.Select onChange={handleSection1SliderSpeed} value={section1SliderSpeed}>
                                {times.map((time) => (
                                    <option value={time}>{time / 1000}초</option>
                                ))}
                            </Form.Select>
                            <Form.Text className="text-muted">
                                1번 섹션의 슬라이더 전환 속도를 선택합니다.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicSelectList">
                            <Form.Label>1번 섹션 슬라이더 효과</Form.Label>
                            <Form.Select onChange={handleSection1SliderEffect} value={section1SliderEffect}>
                                {types.map((type) => (
                                    <option value={type}>
                                        {type === 'slide' ? '슬라이드(slide)' : '페이드(fade)'}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Text className="text-muted">
                                1번 섹션의 슬라이더 전환 효과를 선택합니다.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formFile1" className="mb-3">
                            <Form.Label>슬라이더 1번 이미지 등록/교체</Form.Label>
                            {homeImagesCheck[0] && homeImagesCheck[0] !== '' && (
                                <Form.Text className="text-danger">
                                    1번 이미지 등록되어있습니다. 파일을 선택하고 저장하면 교체됩니다.
                                </Form.Text>
                            )}
                            <Form.Control type="file" onChange={(e) => setFile(e, 0)}/>
                            <Form.Text className="text-muted mr-1">
                                1번 섹션의 슬라이더 이미지를 삭제하려면 체크하세요.
                            </Form.Text>
                            <Form.Check
                                inline
                                name="group1"
                                type={'checkbox'}
                                id={`inline-delete-1`}
                                onChange={(e) => {
                                    handleHomeImageDelete(e, 0);
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formFile2" className="mb-3">
                            <Form.Label>슬라이더 2번 이미지 등록/교체</Form.Label>
                            {homeImagesCheck[1] && homeImagesCheck[1] !== '' && (
                                <Form.Text className="text-danger">
                                    2번 이미지 등록되어있습니다. 파일을 선택하고 저장하면 교체됩니다.
                                </Form.Text>
                            )}
                            <Form.Control type="file" onChange={(e) => setFile(e, 1)}/>
                            <Form.Text className="text-muted mr-1">
                                2번 섹션의 슬라이더 이미지를 삭제하려면 체크하세요.
                            </Form.Text>
                            <Form.Check
                                inline
                                name="group2"
                                type={'checkbox'}
                                id={`inline-delete-2`}
                                onChange={(e) => {
                                    handleHomeImageDelete(e, 1);
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formFile3" className="mb-3">
                            <Form.Label>슬라이더 3번 이미지 등록/교체</Form.Label>
                            {homeImagesCheck[2] && homeImagesCheck[2] !== '' && (
                                <Form.Text className="text-danger">
                                    3번 이미지 등록되어있습니다. 파일을 선택하고 저장하면 교체됩니다.
                                </Form.Text>
                            )}
                            <Form.Control type="file" onChange={(e) => setFile(e, 2)}/>
                            <Form.Text className="text-muted mr-1">
                                3번 섹션의 슬라이더 이미지를 삭제하려면 체크하세요.
                            </Form.Text>
                            <Form.Check
                                inline
                                name="group3"
                                type={'checkbox'}
                                id={`inline-delete-3`}
                                onChange={(e) => {
                                    handleHomeImageDelete(e, 2);
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formFile4" className="mb-3">
                            <Form.Label>슬라이더 4번 이미지 등록/교체</Form.Label>
                            {homeImagesCheck[3] && homeImagesCheck[3] !== '' && (
                                <Form.Text className="text-danger">
                                    4번 이미지 등록되어있습니다. 파일을 선택하고 저장하면 교체됩니다.
                                </Form.Text>
                            )}
                            <Form.Control type="file" onChange={(e) => setFile(e, 3)}/>
                            <Form.Text className="text-muted mr-1">
                                4번 섹션의 슬라이더 이미지를 삭제하려면 체크하세요.
                            </Form.Text>
                            <Form.Check
                                inline
                                name="group4"
                                type={'checkbox'}
                                id={`inline-delete-4`}
                                onChange={(e) => {
                                    handleHomeImageDelete(e, 3);
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicSelectList">
                            <Form.Label>1번 섹션 버튼 내용</Form.Label>
                            <Form.Control type="text" placeholder="CONTACT" onChange={handleChangeSection1BtnText} value={section1BtnText} />
                            <Form.Text className="text-muted">
                                1번 섹션 버튼 내용을 입력하세요.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>2번 섹션 내용</Form.Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={section2Text}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setSection2Text(data);
                                } }
                                onBlur={ editor => {
                                } }
                                onFocus={ editor => {
                                } }
                            />
                        </Form.Group>
                        

                        <Form.Group controlId="formFile5" className="mb-3">
                            <Form.Label>2번 섹션 배경 이미지 등록/교체</Form.Label>
                            {homeImagesCheck[4] && homeImagesCheck[4] !== '' && (
                                <Form.Text className="text-danger">
                                    배경 이미지 등록되어있습니다. 파일을 선택하고 저장하면 교체됩니다.
                                </Form.Text>
                            )}
                            <Form.Control type="file" onChange={(e) => setFile(e, 4)}/>
                            <Form.Text className="text-muted mr-1">
                                2번 섹션의 배경 이미지를 삭제하려면 체크하세요.
                            </Form.Text>
                            <Form.Check
                                inline
                                name="group5"
                                type={'checkbox'}
                                id={`inline-delete-5`}
                                onChange={(e) => {
                                    handleHomeImageDelete(e, 4);
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicSelectList">
                            <Form.Label>2번 섹션 버튼 내용</Form.Label>
                            <Form.Control type="text" placeholder="MORE" onChange={handleChangeSection2BtnText} value={section2BtnText}/>
                            <Form.Text className="text-muted">
                                2번 섹션 버튼 내용을 입력하세요.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>3번 섹션 내용</Form.Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={section3Text}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setSection3Text(data);
                                } }
                                onBlur={ editor => {
                                } }
                                onFocus={ editor => {
                                } }
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicSelectList">
                            <Form.Label>하단 카피라이트 내용</Form.Label>
                            <Form.Control type="text" placeholder="Copyright © *****. All rights reserved." onChange={handleChangeSection3FooterText} value={section3FooterText} />
                            <Form.Text className="text-muted">
                            하단 카피라이트 내용 내용을 입력하세요.
                            </Form.Text>
                        </Form.Group>

                        <div style={{ padding: '10px 0' }}>
                            <Button variant="danger" type='submit' size="lg" disabled={isDisabled}>저장</Button>
                        </div>
                    </Form>
                </Tab>
                <Tab eventKey="profile" title="프로필" style={{ width: '100%', padding: 20 }}>
                    <h3>프로필 페이지 내용 수정</h3>
                    <CKEditor
                        editor={ClassicEditor}
                        data={introText}
                        onReady={ editor => {
                            // You can store the "editor" and use when it is needed.
                        } }
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            setIntroText(data);
                        } }
                        onBlur={ editor => {
                        } }
                        onFocus={ editor => {
                        } }
                    />
                    <div style={{ padding: '10px 0' }}>
                        <Button variant="danger" type='button' onClick={handleSaveIntroData} size="lg">저장</Button>
                    </div>
                </Tab>
                <Tab eventKey="contact" title="이메일" style={{ width: '100%', padding: 20 }}>
                    <h3>이메일 페이지 내용 수정</h3>
                    <Form onSubmit={handleSaveEmailData}>
                        <Form.Group className="mb-3" controlId="formBasicEmailDesctiption">
                            <Form.Label>이메일 작성내용 설명</Form.Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={sendEmailText}
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    setSendEmailText(data);
                                } }
                                onBlur={ editor => {
                                } }
                                onFocus={ editor => {
                                } }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="emailLocationTitle">
                            <Form.Label>도시 선택하는 부분 제목 설정</Form.Label>
                            <Form.Control type="text" placeholder="장소.." onChange={(event) => { setLocationTitle(event.target.value); }} value={locationTitle}/>
                            <Form.Text className="text-muted">
                                도시 선택하는 부분 제목 설정합니다.(예: 예약 장소, LOCATION ...) 도시 목록은 'Settings' 에서 설정할 수 있습니다.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="emailReplyTitle">
                            <Form.Label>회신 이메일 주소 부분 제목 설정</Form.Label>
                            <Form.Control type="text" placeholder="회신 이메일.." onChange={(event) => { setReplyEmail(event.target.value); }} value={replyEmail}/>
                            <Form.Text className="text-muted">
                                회신 이메일 주소 부분 제목 설정합니다. (예: 회신 이메일, REPLY EMAIL ...)
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="emailMessagesTitle">
                            <Form.Label>추가 입력창 항목 설정</Form.Label>
                            <Form.Control type="text" placeholder="예약 전 요청사항1, 문의 사항, 전화 번호, 희망 일자" onChange={(event) => { setEmailMessages(event.target.value); }} value={emailMessages}/>
                            <Form.Text className="text-muted">
                                추가로 입력받으실 입력창을 쉼표(,) 로 나누어 저장하시면 입력하신 항목만큼 제목+입력창이 생성됩니다. <br/>
                                예 ) '요청사항1, 문의 사항, 전화번호, 희망 일자' 로 저장하면 <br/>
                                    요청사항 1 [_____________] <br/>
                                    문의 사항 [_____________] <br/>
                                    전화번호 [_____________] <br/>
                                    희망 일자 [_____________] <br/>
                                위와 같이 입력창이 생성됩니다. 
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="emailImg1">
                            <Form.Label>이미지 파일1 업로드 항목 제목</Form.Label>
                            <Form.Control type="text" placeholder="IMAGE1" onChange={(event) => { setEmailImage1Title(event.target.value); }} value={emailImage1Title}/>
                            <Form.Text className="text-muted">
                                이미지 업로드 부분 1번째 타이틀을 설정합니다.<br/>
                                빈값 (다 지우고 저장) 으로 저장하면 해당 파일 업로드 항목은 보이지 않습니다. 
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="emailImg2">
                            <Form.Label>이미지 파일2 업로드 항목 제목</Form.Label>
                            <Form.Control type="text" placeholder="IMAGE2" onChange={(event) => { setEmailImage2Title(event.target.value); }} value={emailImage2Title}/>
                            <Form.Text className="text-muted">
                                이미지 업로드 부분 2번째 타이틀을 설정합니다.<br/>
                                빈값 (다 지우고 저장) 으로 저장하면 해당 파일 업로드 항목은 보이지 않습니다. 
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="emailImg3">
                            <Form.Label>이미지 파일3 업로드 항목 제목</Form.Label>
                            <Form.Control type="text" placeholder="IMAGE3" onChange={(event) => { setEmailImage3Title(event.target.value); }} value={emailImage3Title}/>
                            <Form.Text className="text-muted">
                                이미지 업로드 부분 3번째 타이틀을 설정합니다.<br/>
                                빈값 (다 지우고 저장) 으로 저장하면 해당 파일 업로드 항목은 보이지 않습니다. 
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="emailImg4">
                            <Form.Label>이미지 파일4 업로드 항목 제목</Form.Label>
                            <Form.Control type="text" placeholder="IMAGE4" onChange={(event) => { setEmailImage4Title(event.target.value); }} value={emailImage4Title}/>
                            <Form.Text className="text-muted">
                                이미지 업로드 부분 4번째 타이틀을 설정합니다.<br/>
                                빈값 (다 지우고 저장) 으로 저장하면 해당 파일 업로드 항목은 보이지 않습니다. 
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="casd3">
                            <Form.Label>스팸방지 필드 제목 (예: 아래 두 숫자를 더해서 입력하세요.)</Form.Label>
                            <Form.Control type="text" placeholder="prevent spam mail." onChange={(event) => { setCaptchaTitle(event.target.value); }} value={captchaTitle}/>
                        </Form.Group>
                        <div style={{ padding: '10px 0' }}>
                            <Button variant="danger" type='button' onClick={handleSaveEmailData} size="lg">저장</Button>
                        </div>
                    </Form>
                    
                </Tab>
            </Tabs>
        </PageContainer>
    );
}

export default Settings;