export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export async function fileToBlob (file) {
  return new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type });
};

export function parseColor (text) {
  const rgbObj = JSON.parse(text);
  return `rgba(${rgbObj.r},${rgbObj.g},${rgbObj.b},${rgbObj.a})`;
}