import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as LoadingSvg } from './loading.svg';
import { useGlobal } from '../provider/global';
import { parseColor } from '../utils/common';

const LoadingContainer = styled.div`
    background-color: ${props => props.color};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
`;

function Loading() {
    const { 
        isLoading,
        settingsData,
    } = useGlobal();

    const [lineColor, setLineColor] = useState('#000');
    const [bgColor, setBgColor] = useState('#FFF');

    useEffect(() => {
        if (!settingsData) return;
        setLineColor(parseColor(settingsData.txtColor));
        setBgColor(parseColor(settingsData.bgColor));
    }, [settingsData]);

    return (
        <>
            {isLoading && (
                <LoadingContainer color={bgColor}>
                    <div style={{ width: 200, height: 200 }}>
                        <LoadingSvg style={{ fill: lineColor }} />
                    </div>
                </LoadingContainer> 
            )} 
        </>
    );
}

export default Loading;
