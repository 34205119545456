import { values } from 'lodash';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PAGE, ROUTE } from './definition';
import { useGlobal } from '../provider/global';

function Router() {
    const { isAuth } = useGlobal();

    return (
      <Switch>
        {values(ROUTE).map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            render={() => {
              if (route.path === PAGE.SETTINGS && !isAuth) return <Redirect to={PAGE.HOME} />;
              return <route.component />;
            }}
          />
        ))}
  
        <Redirect to={PAGE.HOME} />
      </Switch>
    );
  }
  
  export default Router;