import React, { useCallback, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const ColorViewer = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 2px;
    background: rgba(${props => props.color.r},${props => props.color.g},${props => props.color.b},${props => props.color.a});
`;

const SwatchContainer = styled.div`
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
`;

const Popover = styled.div`
    position: absolute;
    z-index: 2;
`;

const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const ButtonContainer = styled.div`
    position: relative;
    text-align: right;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 2px;
    background-color: #fff;
`;

function ColorPicker({ color = { r:0,g:0,b:0,a:1 }, onSelect = () => null }) {
    const [isShow, setIsShow] = useState(false);

    const handleClick = useCallback(() => {
        setIsShow(!isShow);
    }, [isShow]);

    const handleClose = () => {
        setIsShow(false);
    };

    return (
        <div>
        <SwatchContainer onClick={handleClick}>
            <ColorViewer color={color} />
        </SwatchContainer>
            { isShow
            ? (
                <Popover>
                    <Cover onClick={handleClose} />
                    <SketchPicker color={color} onChange={onSelect}/>
                    <ButtonContainer>
                        <Button bsstyle="success" bssize="xs" onClick={handleClose} >선택</Button>
                    </ButtonContainer>
                </Popover>
            ) : null }
        </div>
    );
}

export default ColorPicker;
