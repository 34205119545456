import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGlobal } from '../provider/global';
import styled from 'styled-components';
import Indicator from '../components/indicator';
import { signInWithPopup, signOut } from 'firebase/auth';
import { auth, provider, db } from '../constants/index';
import { doc, getDoc } from 'firebase/firestore';
import { Button, Carousel } from 'react-bootstrap';
import { PAGE } from '../router/definition';
import parser from 'html-react-parser';
import { parseColor } from '../utils/common';
import CommonButton from '../components/commonButton';


const HomeContainer = styled.div`
    height: 300vh;
    width: 100%;
    position: relative;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
`;

const SubContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const FooterContainer = styled.div`
    width: 100%;
    height: 10vh;
    max-height: 50px;
    position: absolute;
    flex: 1;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-item: center;
`;

const CopyRightContainer = styled.div`
    color: ${props => props.color};
    font-weight: thin;
    padding-left: 4%;
`;

const AdminAuthButton = styled.button`
    background-color: transparent;
    color: rgba(100,100,100,0.3);
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    border-radius: 100%;
    margin-right: 4%;
    border-color: rgba(100,100,100,0.3);
`;

const SliderItem = styled.div`
    width: 100%;
    display: block !important;
    background-image: url(${props => props.src});
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const Section1Container = styled.div`
    width: 100%;
    z-index: 5;
    position: relative;
    height: 0;    
`;

const Section1Abs = styled.div`
    position: absolute;
    transform: translateY(-50%);
    width: 50%;
    max-width: 50%;
    top: 50vh;
    margin-left: 25%;
    text-align: center;
    word-break: break-all;
`;

const Section1Abs2 = styled.div`
    position: absolute;
    transform: translateY(-50%);
    width: 50%;
    top: 80vh;
    margin-left: 25%;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Section2Container = styled.div`
    width: 100%;
    position: relative;
    height: 100vh;
    background-image: url(${props => props.bgImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
`;

const Section2Abs = styled.div`
    position: absolute;
    transform: translateY(-50%);
    width: 50%;
    top: 50vh;
    margin-left: 25%;
    text-align: center;
    word-break: break-all;
`;

const Section3Container = styled.div`
    width: 100%;
    position: relative;
    height: 100vh;
`;

const Section3Abs = styled.div`
    position: absolute;
    transform: translateY(-50%);
    width: 50%;
    top: 50vh;
    margin-left: 25%;
    text-align: center;
    word-break: break-all;
`;

function Home() {
    const history = useHistory();
    const { 
        isAuth, 
        setIsAuth, 
        settingsData, 
        homeData, 
        images,
        bgImage,
        setIsLoading,
    } = useGlobal();

    const [sec1Text, setSec1Text] = useState('');
    const [sliderImages, setSliderImages] = useState([]);
    const [sliderInterval, setSliderInterval] = useState(2000);
    const [sliderEffect, setSliderEffect] = useState('slide');
    const [sec1BtnText, setSec1BtnText] = useState('');
    const [sec2BgImage, setSec2BgImage] = useState('');
    const [sec2Text, setSec2Text] = useState('');
    const [sec2BtnText, setSec2BtnText] = useState('');
    const [sec3Text, setSec3Text] = useState('');
    const [sec3Footer, setSec3Footer] = useState('');
    const [bgColor, setBgColor] = useState('');
    const [txtColor, setTxtColor] = useState('');
    const [btnColor, setBtnColor] = useState('');

    const handleOpenGoogleLogin = () => {
        signInWithPopup(auth, provider)
        .then(async (result) => {
            const user = result.user;

            const docRef = doc(db, 'users', user.email);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const userInfo = docSnap.data();
                if (userInfo?.isAdmin) setIsAuth(userInfo.isAdmin);
            } 
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error('error', errorMessage);
        });
    };

    const handleGoogleLogout = () => {
        signOut(auth).then(() => {
            setIsAuth(false);
        }).catch((error) => {
            console.error('error', error);
        });
    };

    const goSettings = useCallback(() => {
        history.push(PAGE.SETTINGS);
    }, [history]);

    useEffect(() => {
        if (!images) return;
        setSliderImages(images);
    }, [images]);

    useEffect(() => {
        if (!bgImage) return;
        setSec2BgImage(bgImage);
    }, [bgImage]);

    useEffect(() => {
        if (!settingsData) return;
        setBgColor(parseColor(settingsData.bgColor));
        setTxtColor(parseColor(settingsData.txtColor));
        setBtnColor(parseColor(settingsData.btnColor));
    }, [settingsData]);

    useEffect(() => {
        if (!homeData) return;
        setSec1Text(homeData.section1Text);
        setSliderInterval(homeData.section1SliderSpeed ? Number(homeData.section1SliderSpeed) : 2000);
        setSliderEffect(homeData.section1SliderEffect ? homeData.section1SliderEffect : 'slide');
        setSec1BtnText(homeData.section1BtnText);
        setSec2Text(homeData.section2Text);
        setSec2BtnText(homeData.section2BtnText);
        setSec3Text(homeData.section3Text);
        setSec3Footer(homeData.section3FooterText);
        setIsLoading(false);
    }, [homeData]);

    return (
        <HomeContainer bgColor={bgColor} color={txtColor}>
            <SubContainer>
                <Section1Container>
                    <Section1Abs>
                        {sec1Text && parser(sec1Text)}
                    </Section1Abs>
                    <Section1Abs2>
                        <CommonButton text={sec1BtnText} route={PAGE.SEND_EMAIL} onClick={() => { setIsLoading(true); }}></CommonButton>
                    </Section1Abs2>
                </Section1Container>
                <Carousel fade={sliderEffect === 'fade'}>
                    {sliderImages.length > 0 && sliderImages.map((image, idx) => (
                        <Carousel.Item interval={sliderInterval}>
                            <SliderItem
                            src={image}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </SubContainer>
            <SubContainer>
                <Section2Container bgImage={sec2BgImage}>
                    <Section2Abs>
                        {sec2Text && parser(sec2Text)}
                    </Section2Abs>
                    <Section1Abs2>
                        <CommonButton text={sec2BtnText} route={PAGE.INTRO} onClick={() => { setIsLoading(true); }}></CommonButton>
                    </Section1Abs2>
                </Section2Container>
            </SubContainer>
            <SubContainer>
                <Section3Container>
                    <Section3Abs>
                        {sec3Text && parser(sec3Text)}
                    </Section3Abs>
                </Section3Container>
                <FooterContainer>
                    <CopyRightContainer>{sec3Footer}</CopyRightContainer>
                    {isAuth ? (
                        <div style={{ display: 'flex', paddingRight: '4%' }}>
                            <Button variant="danger" type='button' onClick={goSettings} style={{ marginRight: 10 }}>Settings</Button>
                            <AdminAuthButton type='button' onClick={handleGoogleLogout}>X</AdminAuthButton>
                        </div>
                    ) : (
                        <AdminAuthButton type='button' onDoubleClick={handleOpenGoogleLogin}>L</AdminAuthButton>
                    )}
                </FooterContainer>
            </SubContainer>
            <Indicator />
        </HomeContainer>
    );
}

export default Home;