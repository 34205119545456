import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useGlobal } from '../provider/global';
import { PAGE } from '../router/definition';
import parser from 'html-react-parser';
import { parseColor } from '../utils/common';
import CommonButton from '../components/commonButton';
import Email from '../js/smtp';
import { Form } from 'react-bootstrap';
import { getBase64 } from '../utils/common';
import { jCaptcha } from '../js/jCaptcha';

const IntroContainer = styled.div`
    min-height: 100vh;
    height: auto;
    width: 100%;
    position: relative;
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    padding: 20vh 20% 20vh 20%;
    text-align: left;
    word-break: break-all;
`;

const BottomContainer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`;

const ButtonContainer = styled.button`
    min-height: 45px;
    height: 5vh;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    font-size: 1.5rem;
    min-width: 200px;
    width: 10%;
    font-weight: 200;
    border-width: 1px;
    border-color: ${props => props.color};
    color: ${props => props.color};
`;


function SendEmail() {
    const history = useHistory();
    const { 
        settingsData,
        sendEmailData,
        sc,
        setIsLoading,
    } = useGlobal();

    const [txtColor, setTxtColor] = useState('');
    const [bgColor, setBgColor] = useState('');
    const [btnColor, setBtnColor] = useState('');
    const [receiveEmail, setReceiveEmail] = useState('');
    const [sendEmailText, setSendEmailText] = useState('');
    const [locationTitle, setLocationTitle] = useState('LOCATION');
    const [replyEmailTitle, setReplyEmailTitle] = useState('REPLY EMAIL');
    const [emailMessages, setEmailMessages] = useState(['MESSAGE']);
    const [emailImage1Title, setEmailImage1Title] = useState('IMAGE');
    const [emailImage2Title, setEmailImage2Title] = useState('');
    const [emailImage3Title, setEmailImage3Title] = useState('');
    const [emailImage4Title, setEmailImage4Title] = useState('');

    const [senderMessage, setSenderMessage] = useState([]);
    const [homeImages, setHomeImages] = useState([null, null, null, null]);
    const [filenames, setFilenames] = useState([null, null, null, null]);

    const [locations, setLocations] = useState([]);
    const [location, setLocation] = useState('');

    const [replyEmail, setReplyEmail] = useState('');

    const [isDisabled, setIsDisabled] = useState(false);

    const [captchaTitle, setCaptchaTitle] = useState('');
    const [captcha, setCaptcha] = useState(null);
    const [captchaInput, setCaptchaInput] = useState('');
    const [captchaErr, setCaptchaErr] = useState(false);

    const handleReplyEmail = (event) => {
        setReplyEmail(event.target.value);
    }

    const handleChangeSenderMessage = useCallback((event, idx) => {
        const senderMessageClone = [...senderMessage];
        senderMessageClone.splice(idx, 1, event.target.value);
        setSenderMessage(senderMessageClone);
    }, [senderMessage]);

    const setFile = useCallback(async (event, number) => {
        const file = event.target.files[0];
        const type = file.type;
        if (type.search('image') === -1) return;
        const base64 = await getBase64(file);
        const cloneHomeImages = [...homeImages];
        const cloneFilenames = [...filenames];
        cloneHomeImages.splice(number, 1, base64);
        cloneFilenames.splice(number, 1, file.name);
        setHomeImages(cloneHomeImages);
        setFilenames(cloneFilenames);
    }, [homeImages, filenames]);

    const submitEmail = useCallback(async (event) => {
        event.preventDefault();
        if (isDisabled) return;
        if (captcha) {
            const res = captcha.validate();
            if (!res || res == 'error') {
                setCaptchaErr(true);
                return;
            }
        }
        setIsDisabled(true);
        const attaches = homeImages.map((img, idx) => {
            return {
                name: filenames[idx],
                data: img
            };
        }).filter((data) => data.name && data.data);

        let body = `${locationTitle}: ${location}<br/>${replyEmailTitle}: ${replyEmail}<br/>`;

        emailMessages.forEach((title, idx) => {
            body += `${title}: ${senderMessage[idx]}<br/>`;
        });

        Email.send({
            SecureToken : sc,
            To : receiveEmail,
            From : "tattoo__request__message^amgil__com".replace('^', '@').replace(/__/gi, '.').replace('amg','gma'),
            Subject : `[${location}] TATTOO REQUST : ${replyEmail}`,
            Body : body,
            Attachments :attaches,
        });
        alert('Email sending is complete.');
        setIsDisabled(false);
        setIsLoading(true);
        history.replace(PAGE.HOME);
    },[
        captcha,
        sc, 
        Email,
        receiveEmail,
        location,
        locationTitle,
        emailMessages,
        senderMessage,
        replyEmail,
        replyEmailTitle,
        homeImages,
        filenames,
        history,
        isDisabled,
    ]);

    const handleLocation = (event) => {
        setLocation(event.target.value);
    };

    const handleCaptcha = (event) => {
        setCaptchaErr(false);
        setCaptchaInput(event.target.value);
    };

    useEffect(() => {
        if (!settingsData) return;
        setTxtColor(parseColor(settingsData.txtColor));
        setBgColor(parseColor(settingsData.bgColor));
        setBtnColor(parseColor(settingsData.btnColor));
        setReceiveEmail(settingsData.receiveEmail);
        setLocations(settingsData.cities);
        setLocation(settingsData.cities[0]);
    }, [settingsData]);

    useEffect(() => {
        if (!sendEmailData) return;
        setSendEmailText(sendEmailData.mainText);
        setLocationTitle(String(sendEmailData.locationTitle).trim());
        setReplyEmailTitle(String(sendEmailData.replyEmailTitle).trim());
        const messages = String(sendEmailData.messageTitles).trim();
        const messagesArr = messages.split(',').filter((title) => title.trim() !== '');
        setEmailMessages(messagesArr);
        setSenderMessage(new Array(messagesArr.length));
        setEmailImage1Title(String(sendEmailData.image1Title).trim());
        setEmailImage2Title(String(sendEmailData.image2Title).trim());
        setEmailImage3Title(String(sendEmailData.image3Title).trim());
        setEmailImage4Title(String(sendEmailData.image4Title).trim());
        setCaptchaTitle(String(sendEmailData.captchaTitle).trim());
        setIsLoading(false);
    }, [sendEmailData]);

    useEffect(() => {
        setCaptcha(new jCaptcha({
            el: '.jCaptcha',
            canvasClass: 'jCaptchaCanvas',
            canvasStyle: {
                // required properties for captcha stylings:
                width: 100,
                height: 15,
                textBaseline: 'top',
                font: '15px Arial',
                textAlign: 'left',
                fillStyle: '#ddd'
            },
            // set callback function for success and error messages:
            callback: ( response, $captchaInputElement, numberOfTries ) => {
                if ( response == 'success' ) {
                    // success handle, e.g. continue with form submit
                }
                if ( response == 'error' ) {
                    // error handle, e.g. add error class to captcha input
    
                    if (numberOfTries === 3) {
                        // maximum attempts handle, e.g. disable form
                    }
                }
            }
        }));
    }, []);

    return (
        <IntroContainer bgColor={bgColor} color={txtColor}>
            {sendEmailText && parser(sendEmailText)}
            <Form onSubmit={submitEmail}>
                <Form.Group className="mb-3" controlId="formBasicSelectList">
                    <Form.Label>{locationTitle}</Form.Label>
                    <Form.Select onChange={handleLocation} value={location}>
                        {locations.map((loca) => (
                            <option value={loca}>{loca}</option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="replyEmail3">
                    <Form.Label>{replyEmailTitle}</Form.Label>
                    <Form.Control type="email" placeholder="reply@email.com" onChange={handleReplyEmail} value={replyEmail}/>
                </Form.Group>
                
                {emailMessages.length > 0 && emailMessages.map((messageTitle, idx) => (
                    <Form.Group key={`tt-${idx}`} className="mb-3" controlId={`sendMsg-${idx}`}>
                        <Form.Label>{messageTitle}</Form.Label>
                        <Form.Control as="textarea"
                            placeholder="Leave a message here"
                            style={{ height: '60px' }}
                            onChange={(event) => handleChangeSenderMessage(event, idx)} 
                            value={senderMessage[idx]} />
                    </Form.Group>
                ))}
                
                {emailImage1Title && emailImage1Title !== '' && (
                    <Form.Group controlId="formFile1Image" className="mb-3">
                        <Form.Label>{emailImage1Title}</Form.Label>
                        <Form.Control type="file" onChange={(e) => setFile(e, 0)}/>
                    </Form.Group>
                )}
                
                {emailImage2Title && emailImage2Title !== '' && (
                    <Form.Group controlId="formFile2Image" className="mb-3">
                        <Form.Label>{emailImage2Title}</Form.Label>
                        <Form.Control type="file" onChange={(e) => setFile(e, 1)}/>
                    </Form.Group>
                )}

                {emailImage3Title && emailImage3Title !== '' && (
                    <Form.Group controlId="formFile3Image" className="mb-3">
                        <Form.Label>{emailImage3Title}</Form.Label>
                        <Form.Control type="file" onChange={(e) => setFile(e, 2)}/>
                    </Form.Group>
                )}

                {emailImage4Title && emailImage4Title !== '' && (
                    <Form.Group controlId="formFile4Image" className="mb-3">
                        <Form.Label>{emailImage4Title}</Form.Label>
                        <Form.Control type="file" onChange={(e) => setFile(e, 3)}/>
                    </Form.Group>
                )}
                <Form.Label className="mr-2">{captchaTitle}</Form.Label>
                <Form.Group className="mb-3 w-50" controlId="notrobot">
                    <Form.Control className="jCaptcha" type="text" placeholder="Type in result please." onChange={handleCaptcha} value={captchaInput} isInvalid={captchaErr}/>
                    <Form.Text className="text-muted">
                    I'm not a robot.
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBtn" className="mb-3 mt-5">
                    <ButtonContainer type='submit' color={btnColor}>SEND</ButtonContainer>
                </Form.Group>
            </Form>
            <BottomContainer>
                <CommonButton text={'home'} route={PAGE.HOME} onClick={() => { setIsLoading(true); }} isBack={true}></CommonButton>
            </BottomContainer>
        </IntroContainer>
    );
}

export default SendEmail;