import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useGlobal } from '../provider/global';
import styled from 'styled-components';
import { parseColor } from '../utils/common';
import { ReactComponent as BackSvg } from './goback.svg';
import { PAGE } from '../router/definition';

const ButtonContainer = styled.button`
    min-height: 45px;
    height: 5vh;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    font-size: 1.5rem;
    min-width: 200px;
    width: 10%;
    font-weight: 200;
    border-width: 1px;
    border-color: ${props => props.color};
    border-style: solid;
    color: ${props => props.color};
    &:hover {
        background-color: ${props => { 
            const colors = props.color.split(',');
            const opacity = '0.05)';
            colors.splice(3,1,opacity);
            return colors.join(',');
        }};
        border-color: ${props => { 
            const colors = props.color.split(',');
            const opacity = '0.8)';
            colors.splice(3,1,opacity);
            return colors.join(',');
        }};
    }
`;

const BackButtonContainer = styled.button`
    border: none;
    background: transparent;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
`;

function CommonButton({ text, route, onClick = undefined , isBack = false }) {
    const history = useHistory();
    const { settingsData } = useGlobal();
    const [btnColor, setBtnColor] = useState('');

    const goRoute = useCallback(() => {
        if (onClick) onClick();
        history.push(route);
    }, [history]);

    const goHome = useCallback(() => {
        if (onClick) onClick();
        history.push(PAGE.HOME);
    }, [history]);

    useEffect(() => {
        if (!settingsData) return;
        setBtnColor(parseColor(settingsData.btnColor));
    }, [settingsData]);

    return (
        <>
        {isBack ? (
            <BackButtonContainer onClick={goHome}>
                <BackSvg style={{ fill: btnColor }}></BackSvg>
            </BackButtonContainer>
        ) : (
            <ButtonContainer color={btnColor} type='button' onClick={goRoute}>
                {text}
            </ButtonContainer>
        )}
        </>
    );
}

export default CommonButton;