import React, { useEffect, useState } from 'react';
import { useGlobal } from '../provider/global';
import styled from 'styled-components';
import parser from 'html-react-parser';
import { parseColor } from '../utils/common';

const Container = styled.div`
    min-height: 60px;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: transparent;
    padding-top: 2vh;
    z-index: 100;
`;

const HeaderContainer = styled.div`
    color: ${props => props.color};
`;

function Header() {
    const { settingsData } = useGlobal();
    const [txtColor, setTxtColor] = useState('');
    const [headerTitle, setHeaderTitle] = useState('');

    useEffect(() => {
        if (!settingsData) return;
        setTxtColor(parseColor(settingsData.txtColor));
        setHeaderTitle(settingsData.headerTitle);
    }, [settingsData]);
    return (
        <Container>
            <HeaderContainer color={txtColor}>
                {headerTitle && parser(headerTitle)}
            </HeaderContainer>
        </Container>
    );
}

export default Header;