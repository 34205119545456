import styled from 'styled-components';

export const PageContainer = styled.div`
    padding: 60px 15px 30px;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;