import Home from '../page/home';
import Intro from '../page/intro';
import SendEmail from '../page/sendEmail';
import Settings from '../page/settings';

export const PAGE = {
    HOME: '/',
    INTRO: '/intro',
    SEND_EMAIL: '/send-email',
    SETTINGS: '/settings',
};

export const ROUTE = {
    [PAGE.HOME]: {
        path: PAGE.HOME,
        component: Home,
    },
    [PAGE.INTRO]: {
        path: PAGE.INTRO,
        component: Intro,
    },
    [PAGE.SEND_EMAIL]: {
        path: PAGE.SEND_EMAIL,
        component: SendEmail,
    },
    [PAGE.SETTINGS]: {
        path: PAGE.SETTINGS,
        component: Settings,
    },
}