import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { db, storage, auth } from "../constants";
import { doc, getDoc } from "firebase/firestore";
import { signInAnonymously } from 'firebase/auth';

const GlobalContext = createContext({});

function useGlobal() {
    return useContext(GlobalContext);
}

function GlobalProvider({ children }) {
    const [anonymousAuth, setAnonymousAuth] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [settingsData, setSettingsData] = useState();
    const [homeData, setHomeData] = useState();
    const [introData, setIntroData] = useState();
    const [sendEmailData, setSendEmailData] = useState();
    const [changeFlag, setChangeFlag] = useState(1);
    const [prevFlag, setPrevFlag] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [images, setImages] = useState([]);
    const [bgImage, setBgImage] = useState();
    const [sc, setSc] = useState();

    useEffect(() => {
      if (!anonymousAuth) return;
      if (!(changeFlag > prevFlag)) return;
      setPrevFlag(changeFlag);
      (async () => {
        const settingsRef = doc(db, "settings", "settings");
        const settingsSnap = await getDoc(settingsRef);
  
        if (settingsSnap.exists()) {
          setSettingsData(settingsSnap.data());
        } else {
          setSettingsData(undefined);
        }

        const homeRef = doc(db, "settings", "home");
        const homeSnap = await getDoc(homeRef);

        if (homeSnap.exists()) {
          const homeData = homeSnap.data();
          setHomeData(homeData);
          const images = homeData.homeImages;
          const imagesSrcs = [];
          let n = 0;
          const imagesRef = storage.ref().child('images');
          if (images[n]) {
            const imgRef = imagesRef.child(images[n]);
            const url = await imgRef.getDownloadURL();
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            imagesSrcs.push(url);
            setImages(imagesSrcs);
            setIsLoading(false);
          }
          n++;
          if (images[n]) {
            const imgRef = imagesRef.child(images[n]);
            const url = await imgRef.getDownloadURL();
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            imagesSrcs.push(url);
          }
          n++;
          if (images[n]) {
            const imgRef = imagesRef.child(images[n]);
            const url = await imgRef.getDownloadURL();
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            imagesSrcs.push(url);
          }
          n++;
          if (images[n]) {
            const imgRef = imagesRef.child(images[n]);
            const url = await imgRef.getDownloadURL();
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            imagesSrcs.push(url);
          }
          setImages(imagesSrcs);
          n++;
          if (images[n]) {
            const imgRef = imagesRef.child(images[n]);
            const url = await imgRef.getDownloadURL();
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
              const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            setBgImage(url);
          }
        } else {
          setHomeData(undefined);
        }
        setIsLoading(false);

        const introRef = doc(db, "settings", "intro");
        const introSnap = await getDoc(introRef);

        if (introSnap.exists()) {
          setIntroData(introSnap.data());
        } else {
          setIntroData(undefined);
        }

        const sendEmailRef = doc(db, "settings", "sendEmail");
        const sendEmailSnap = await getDoc(sendEmailRef);

        if (sendEmailSnap.exists()) {
          setSendEmailData(sendEmailSnap.data());
        } else {
          setSendEmailData(undefined);
        }

        const scRef = doc(db, "sc", "key");
        const scSnap = await getDoc(scRef);
  
        if (scSnap.exists()) {
          setSc(scSnap.data().sender);
        } else {
          setSc(undefined);
        }
      })();
    }, [changeFlag, prevFlag, anonymousAuth]);

    useEffect(() => {
      signInAnonymously(auth)
      .then(() => {
        setAnonymousAuth(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setAnonymousAuth(false);
      });
    }, []);

    return (
        <GlobalContext.Provider
          value={{
            isLoading,
            isAuth,
            settingsData,
            homeData,
            introData,
            sendEmailData,
            changeFlag,
            images,
            bgImage,
            sc,
            setIsAuth,
            setSettingsData,
            setHomeData,
            setIntroData,
            setSendEmailData,
            setChangeFlag,
            setIsLoading,
          }}>
          {children}
        </GlobalContext.Provider>
    );
}

export { useGlobal, GlobalProvider };