import React from 'react';
import { GlobalProvider } from './global';


function Provider({ children }) {
    return (
        <GlobalProvider>
            {children}
        </GlobalProvider>
    );
};

export default Provider;