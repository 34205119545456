import firebase from "firebase/compat/app"
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import "firebase/compat/storage";

// graycode tattoo
// const firebaseConfig = {
//   apiKey: "AIzaSyCBp8z6w3YMA0luvbE3dQ_Is_cB1bDZNao",
//   authDomain: "graycode-tattoo.firebaseapp.com",
//   projectId: "graycode-tattoo",
//   storageBucket: "graycode-tattoo.appspot.com",
//   messagingSenderId: "436008298793",
//   appId: "1:436008298793:web:46ca02299f509ea9f4ef38"
// };
// nanaorient-tattoo
// const firebaseConfig = {
//   apiKey: "AIzaSyBjivNSWeDx2w21HnNNv3VRcDH6lke9l_4",
//   authDomain: "nanaorient-tattoo.firebaseapp.com",
//   projectId: "nanaorient-tattoo",
//   storageBucket: "nanaorient-tattoo.appspot.com",
//   messagingSenderId: "162082248862",
//   appId: "1:162082248862:web:7a5c41b1961801864f438f"
// };
// 88world
const firebaseConfig = {
  apiKey: "AIzaSyCnYjN_uMYOoyf1gpaYJlFpQuaXw0wBpZI",
  authDomain: "i88world.firebaseapp.com",
  projectId: "i88world",
  storageBucket: "i88world.appspot.com",
  messagingSenderId: "905163539839",
  appId: "1:905163539839:web:a5e98a82eb82169a8c464a"
};
// tattooist-340
// const firebaseConfig = {
//   apiKey: "AIzaSyA5oJ9vKNxD_zRY67qmlfJwcaeMmVijTuU",
//   authDomain: "tattooist-340.firebaseapp.com",
//   projectId: "tattooist-340",
//   storageBucket: "tattooist-340.appspot.com",
//   messagingSenderId: "855947343439",
//   appId: "1:855947343439:web:39f2695c4a2fa86883dc3a"
// };
// opiumink
// const firebaseConfig = {
//   apiKey: "AIzaSyBGo1zB4M64d7hEEBLx6L0qWje9Q4OiuPo",
//   authDomain: "opium-ink.firebaseapp.com",
//   projectId: "opium-ink",
//   storageBucket: "opium-ink.appspot.com",
//   messagingSenderId: "60669259309",
//   appId: "1:60669259309:web:76973f07e8359bf24352a4"
// };
// vocoink
// const firebaseConfig = {
//   apiKey: "AIzaSyDgfladkPXhzd_ycauqwx8m_OLH5InOilo",
//   authDomain: "vocoink.firebaseapp.com",
//   projectId: "vocoink",
//   storageBucket: "vocoink.appspot.com",
//   messagingSenderId: "62290778343",
//   appId: "1:62290778343:web:5bda68ba470a8960c7ea42"
// };
// tattooistyeon
// const firebaseConfig = {
//   apiKey: "AIzaSyBIddQdtqE0HtqTBMrd69oJRfsd0BjGgwg",
//   authDomain: "tattooist-yeon.firebaseapp.com",
//   projectId: "tattooist-yeon",
//   storageBucket: "tattooist-yeon.appspot.com",
//   messagingSenderId: "775280154603",
//   appId: "1:775280154603:web:6dc6571237501f557eebae"
// };
// bloochink
// const firebaseConfig = {
//   apiKey: "AIzaSyBMrhw0FEwd-6vHrDxvGRl79Ya8UTynoaQ",
//   authDomain: "bloochipink.firebaseapp.com",
//   projectId: "bloochipink",
//   storageBucket: "bloochipink.appspot.com",
//   messagingSenderId: "535267893250",
//   appId: "1:535267893250:web:a4bacc04885e183fb5471b"
// };
// ujuntat
// const firebaseConfig = {
//   apiKey: "AIzaSyBFCpOzjof__kChB8PsGMneY_qn4fBkcRg",
//   authDomain: "ujuntat.firebaseapp.com",
//   projectId: "ujuntat",
//   storageBucket: "ujuntat.appspot.com",
//   messagingSenderId: "1051864024885",
//   appId: "1:1051864024885:web:4d0fc6ef46ada8a2236e7c"
// };
// kez-tattoo
// const firebaseConfig = {
//   apiKey: "AIzaSyBpc1_bLG-4PqkcWizKK3WzY5JP5izkjYc",
//   authDomain: "kez-tattoo.firebaseapp.com",
//   projectId: "kez-tattoo",
//   storageBucket: "kez-tattoo.appspot.com",
//   messagingSenderId: "1070712697232",
//   appId: "1:1070712697232:web:16160d046bfce68dde8315"
// };

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

export const storage = firebase.storage();

export const auth = getAuth();

export const provider = new GoogleAuthProvider();

